<template>
  <div class="home">
    <Header :type="$route.query.type" />
    <div class="c">
      <div class="c-top" v-show="$route.query.type != '3'">
        <div class="header-nav">
          <div
            v-for="(item, index) in tabList"
            :key="item"
            class="header-nav-item"
            :class="activeIndex === index ? 'active' : ''"
            @click="changeList(index)"
          >
            {{ item }}
          </div>
        </div>
        <div class="search-box">
          <el-input
            v-model="courseName"
            suffix-icon="el-icon-search"
            placeholder="請輸入名稱"
          ></el-input>
        </div>
      </div>
      <div class="c-content">
        <Active
          :activeList="list"
          :show="false"
          v-if="$route.query.type != 3 && list.length > 0"
          :fullpath="'active'"
          :type="Number($route.query.type)"
        />
        <Branch
          :list="list"
          :show="false"
          v-if="$route.query.type == 3 && list.length > 0"
        />
        <Pagination
          v-show="query.total > 0"
          :total="query.total"
          :page="query.page"
          :limit="query.limit"
          @pagination="pagination"
        />
        <NoData v-if="list.length <= 0" imgType="course" hint="暂无内容" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header-one/index.vue";
import Active from "@/components/home/active.vue";
import Pagination from "@/components/pagination.vue";
import NoData from "@/components/no-data.vue";
import { getActiveList, getClassList, getBranchList } from "@/api/home.js";
import Branch from "@/components/home/branch.vue";
export default {
  name: "Home",
  components: { Header, Active, Pagination, NoData, Branch },
  data() {
    return {
      tabList: ["全部", "进行中", "还未开始", "已过期"],
      activeIndex: 0,
      query: {
        page: 1,
        limit: 40,
        total: 0,
      },
      list: [],
      courseName: "",
    };
  },
  created() {
    console.log(this.$route.query.type);
    this.getList();
  },
  watch: {
    "$route.query.type"(val) {
      if (val) {
        this.query = {
          page: 1,
          limit: 40,
          total: 0,
        };
        this.getList();
      }
    },
  },
  methods: {
    getList() {
      const query = {
        ...this.query,
        type: this.activeIndex === 0 ? null : this.activeIndex,
      };
      if (this.$route.query.type === "1") {
        getActiveList(query).then((res) => {
          if (res.code === 0) {
            this.list = res.data.list;
            this.query.total = res.data.total;
          }
        });
      } else if (this.$route.query.type === "2") {
        getClassList(query).then((res) => {
          if (res.code === 0) {
            this.list = res.data.list;
            this.query.total = res.data.total;
          }
        });
      } else if (this.$route.query.type === "3") {
        getBranchList(query).then((res) => {
          if (res.code === 0) {
            this.list = res.data.list;
            this.query.total = res.data.total;
          }
        });
      }
    },
    pagination(page) {
      this.query.page = page.page;
      this.query.limit = page.limit;
      this.getList();
    },
    changeList(index) {
      this.activeIndex = index;
      this.query.page = 1;
      this.query.limit = 40;
      this.query.total = 0;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.c {
  width: 1200px;
  margin: 45px auto 35px;
}
.c-top {
  position: relative;
  border-bottom: 1px solid #eeeeee;
}
.header-nav {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-right: 218px;
  .header-nav-item {
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    font-size: 16px;
    margin-right: 35px;
    cursor: pointer;
  }
  .active {
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #f5394c;
    position: relative;
    padding-bottom: 5px;
  }
  .active:after {
    content: " ";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 90%;
    height: 3px;
    background: #f5394c;
    transform: translateX(-50%;);
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu--horizontal > .el-menu-item.is-active {
  //   margin-bottom: 5px;
}
.el-menu--horizontal > .el-menu-item {
  height: 30px;
  line-height: 30px;
}

.search-box {
  position: absolute;
  width: 360px;
  height: 40px;
  top: -20px;
  right: 30px;
  .search-btn {
    position: absolute;
    right: 0px;
    top: -4px;
    width: 50px;
    height: 38px;
    cursor: pointer;
  }
  /deep/.el-input__inner {
    background: #f6f6f6 !important;
    border-radius: 20px;
  }
}

.c-content {
  padding: 30px 0;
}
</style>
